import { useCallback, useEffect } from 'react';
import { extentFromGeo } from '@fieldmargin/webapp-geo';
import { setMapView } from 'farm-editing/farm-editing-state';
import { useEditingGeoFeatureCollection } from 'hooks/useEditingGeoFeatureCollection';
import { useAppDispatch } from 'system/store';

import { useEditFieldNameParams } from './useEditFieldNameParams';

const useZoomToCurrentFeature = () => {
    const dispatch = useAppDispatch();
    const { editFieldName } = useEditFieldNameParams();
    const { editingGeoFeatureCollection } = useEditingGeoFeatureCollection();

    const currentEditingFeature = editingGeoFeatureCollection?.get('features')?.get(0);

    const zoomToCurrentFeature = useCallback(() => {
        if (!editFieldName) return;
        if (!currentEditingFeature) return;

        dispatch(
            setMapView({
                type: 'extent',
                payload: extentFromGeo(currentEditingFeature),
            })
        );
    }, [editFieldName]);

    const resetZoomHandler = useCallback(() => {
        dispatch(setMapView({ type: 'full-extent' }));
    }, [dispatch]);

    useEffect(() => {
        zoomToCurrentFeature();

        return () => {
            resetZoomHandler();
        };
    }, [zoomToCurrentFeature, resetZoomHandler]);
};

export { useZoomToCurrentFeature };
