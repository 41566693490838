import { ButtonBase } from 'components/button-base';
import { usePageParams } from 'hooks/usePageParamas';
import FieldmarginLogo from 'top-nav/FieldmarginLogo';
import SettingsNavItem from 'top-nav/SettingsNavItem';

import { useAppTranslate } from '../../hooks/useAppTranslate';
import { useReturnToAgreena } from '../../hooks/useReturnToAgreena';

import styles from './Header.module.css';

function Header() {
    const { t_agreena } = useAppTranslate();
    const { returnToAgreenaAddFields } = useReturnToAgreena();
    const { farmUuid } = usePageParams();
    const navigateTo = farmUuid ? `/farms/${farmUuid}/fields` : '/';

    return (
        <header className={styles.header}>
            <FieldmarginLogo to={navigateTo} />

            <div className={styles.headerButtonsGroup}>
                <ButtonBase.Root
                    className={styles.buttonHeader}
                    variant="outline-primary"
                    onPress={returnToAgreenaAddFields}
                >
                    {t_agreena('completion_return_to_agreena')}
                </ButtonBase.Root>

                <SettingsNavItem />
            </div>
        </header>
    );
}

export { Header };
