import type { RouteObject } from 'react-router-dom';
import { APP_ROUTES } from '@notes-web/types';
import ErrorMessage from 'view/ErrorMessage';

import { RPAImportContainer } from '../components/RPAImportContainer';

const rpaRoutes: RouteObject = {
    path: APP_ROUTES.Fields.RPA,
    element: <RPAImportContainer />,
    errorElement: (
        <ErrorMessage className="p-5">
            Something went wrong when importing boundaries, please refresh the page.
        </ErrorMessage>
    ),
};

export { rpaRoutes };
