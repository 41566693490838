import { useNavigate } from 'react-router-dom';
import { Sidebar } from '@notes-web/components';
import { useSearchAutoBoundaryArea } from 'hooks/useSearchAutoBoundaryArea';

import { useAppTranslate } from '../../hooks/useAppTranslate';

function DetectAutoBoundaries() {
    const navigate = useNavigate();
    const { t_agreena, t } = useAppTranslate();
    const { onSearchAutoBoundaryArea, isPending, isDisabled } = useSearchAutoBoundaryArea(() =>
        navigate('select')
    );

    return (
        <Sidebar.Root>
            <Sidebar.Body>
                <Sidebar.Navigation />

                <Sidebar.Header>
                    <Sidebar.Heading>{t_agreena('detect_auto_boundaries_heading')}</Sidebar.Heading>
                    <Sidebar.Description>
                        {t_agreena('detect_auto_boundaries_description')}
                    </Sidebar.Description>
                </Sidebar.Header>
            </Sidebar.Body>

            <Sidebar.Footer>
                <Sidebar.ButtonFooter onPress={onSearchAutoBoundaryArea} isDisabled={isDisabled}>
                    {isPending ? t('searching') : t('search')}
                </Sidebar.ButtonFooter>
            </Sidebar.Footer>
        </Sidebar.Root>
    );
}

export { DetectAutoBoundaries };
