import { useEffect } from 'react';
import { useDrawFieldActions } from 'hooks/useDrawFieldActions';
import { usePageParams } from 'hooks/usePageParamas';

const useEditingNewFieldEffect = () => {
    const { fieldUuid } = usePageParams();
    const { startFieldEditingHandler } = useDrawFieldActions();

    useEffect(() => {
        if (fieldUuid) return;

        startFieldEditingHandler();
    }, [startFieldEditingHandler, fieldUuid]);
};

export { useEditingNewFieldEffect };
