import { useMemo } from 'react';
import { useFindByUserWithOwnersQuery } from '@notes-web/api';

import { useAgreenaToken } from './useAgreenaToken';

const useIsExistingFarmId = () => {
    const { data, isLoading } = useFindByUserWithOwnersQuery();
    const { agreenaToken } = useAgreenaToken();

    const existingFarmId = useMemo(() => {
        if (agreenaToken?.farmId) {
            return (
                data?.find((farm) => farm?.externalId === agreenaToken.farmId.toString())?.uuid ||
                null
            );
        }
        return null;
    }, [data, agreenaToken?.farmId]);

    return { existingFarmId, isLoading };
};

export { useIsExistingFarmId };
