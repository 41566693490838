import type { ChangeEvent } from 'react';
import { Form } from 'react-aria-components';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import type { GeoFeature } from '@fieldmargin/webapp-geo';
import { Sidebar } from '@notes-web/components';
import { highlightGeoFeatureId, stopEditing } from 'farm-editing/farm-editing-state';
import { useAutoBoundaryNameForm } from 'hooks/useAutoBoundaryNameForm';
import { useCheckFieldNamesDuplicates } from 'hooks/useCheckFieldnamesDuplicates';
import { useEditingFieldUuids } from 'hooks/useEditingFieldUuids';
import { useEditingGeoFeatureCollection } from 'hooks/useEditingGeoFeatureCollection';
import { useAppDispatch } from 'system/store';

import { useAppTranslate } from '../../hooks/useAppTranslate';
import { InputField } from '../InputField';

import styles from './SaveAutoBoundaryFields.module.css';

interface FormValueSchema {
    names: { [k: string]: string };
    ids?: { [k: string]: string };
}

function SaveAutoBoundaryFields() {
    const { t, t_agreena } = useAppTranslate();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { editingFieldUuids } = useEditingFieldUuids();
    const { editingGeoFeatureCollection } = useEditingGeoFeatureCollection();
    const { isDuplicate, hasDuplicates } = useCheckFieldNamesDuplicates();

    const { onSubmit, isPending, onRemoveField, onAddField, onNameChange, onHighlightGeoFeature } =
        useAutoBoundaryNameForm();

    const onSaveFields = (data: FormValueSchema) => {
        onSubmit(data, () => {
            dispatch(stopEditing());
            navigate('../../../../fields');
        });
    };

    const isIncluded = (feature: GeoFeature) => editingFieldUuids.includes(feature.id.toString());

    const onFocus = (feature: GeoFeature) => () => {
        onHighlightGeoFeature(feature.id.toString());
    };

    const onBlur = () => {
        dispatch(highlightGeoFeatureId());
    };

    const onChange = (featureId: string | number, e: ChangeEvent<HTMLInputElement>) => {
        onNameChange(featureId, e);
    };

    const { control, handleSubmit, resetField, formState, getValues } = useForm<FormValueSchema>({
        mode: 'onChange',
        reValidateMode: 'onChange',
    });

    const isFormSubmitDisable = formState.isLoading || !formState.isValid;

    const onAddRemove = (feature: GeoFeature) => () => {
        if (isIncluded(feature)) {
            onRemoveField(feature.id);
            resetField(`names.${feature.id}`);
            resetField(`ids.${feature.id}`);
        } else {
            onAddField(feature.id);
        }
    };

    const isDisabled =
        isPending ||
        isFormSubmitDisable ||
        editingFieldUuids.size === 0 ||
        hasDuplicates(getValues().names);

    return (
        <Sidebar.Root>
            <Sidebar.Body>
                <Sidebar.Navigation />
                <Sidebar.Card>
                    <Sidebar.Heading>{t('field_boundary_auto_edit_names_title')}</Sidebar.Heading>

                    <Form autoComplete="off" className={styles.form}>
                        {editingGeoFeatureCollection?.features.map((feature, index) => (
                            <InputField.Group variant="border" key={feature.id}>
                                <InputField.Root<FormValueSchema>
                                    key={`${feature.id}-${!isIncluded(feature)}`}
                                    name={{
                                        field: `names.${feature.id}`,
                                        id: `ids.${feature.id}`,
                                    }}
                                    control={control}
                                    autoFocus={index === 0}
                                    onChange={(e) => onChange(feature.id, e)}
                                    onFocus={onFocus(feature)}
                                    onBlur={onBlur}
                                    isDisabled={!isIncluded(feature)}
                                    isDuplicate={isDuplicate({
                                        names: getValues().names,
                                        featureId: feature.id,
                                    })}
                                />

                                <InputField.Toggle
                                    onPress={onAddRemove(feature)}
                                    isIncluded={isIncluded(feature)}
                                />
                            </InputField.Group>
                        ))}
                    </Form>
                </Sidebar.Card>
            </Sidebar.Body>

            <Sidebar.Footer>
                <Sidebar.ButtonFooter
                    onPress={() => handleSubmit((data) => onSaveFields(data))()}
                    isDisabled={isDisabled}
                >
                    {t_agreena('detect_auto_boundaries_save_fields')}
                </Sidebar.ButtonFooter>
            </Sidebar.Footer>
        </Sidebar.Root>
    );
}

export { SaveAutoBoundaryFields };
