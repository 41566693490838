import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { analytics } from '@notes-web/analytics';

import { useAgreenaToken } from '../hooks/useAgreenaToken';

function TrackPage() {
    const location = useLocation();
    const { agreenaToken } = useAgreenaToken();

    useEffect(() => {
        analytics.page({
            agreena: {
                farmId: agreenaToken?.farmId,
                farmName: agreenaToken?.farmName,
                email: agreenaToken?.email,
            },
        });
    }, [location.pathname, agreenaToken]);

    return <Outlet />;
}
export { TrackPage };
