import { useAgreenaToken } from './useAgreenaToken';
import { useAgreenaTracking } from './useAgreenaTracking';

function trimUrlToAddFields(url: string) {
    const parsedUrl = new URL(url);
    const pathSegments = parsedUrl.pathname.split('/');
    const targetIndex = pathSegments.indexOf('add-fields');

    if (targetIndex !== -1) {
        const newPathname = pathSegments.slice(0, targetIndex + 1).join('/');
        parsedUrl.pathname = newPathname;
        return parsedUrl.toString();
    }
    //  fallback original url
    return url;
}

const useReturnToAgreena = () => {
    const { agreenaToken } = useAgreenaToken();
    const { trackReturnToAgreena } = useAgreenaTracking();

    const returnToAgreenaAddFields = () => {
        const parsedUrl = trimUrlToAddFields(agreenaToken?.redirectUrl ?? '');
        if (parsedUrl) {
            trackReturnToAgreena();
            window.location.href = parsedUrl;
        }
    };

    const returnToAgreenaComplete = () => {
        if (agreenaToken?.redirectUrl) {
            trackReturnToAgreena();
            window.location.href = agreenaToken.redirectUrl;
        }
    };

    return {
        returnToAgreenaAddFields,
        returnToAgreenaComplete,
    };
};

export { useReturnToAgreena };
