import type { ComponentProps } from 'react';

function DeleteIcon({ fill = 'currentColor', ...props }: ComponentProps<'svg'>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 19" fill={fill} {...props}>
            <path d="M5.25 16.25c-.412 0-.766-.147-1.06-.44a1.445 1.445 0 0 1-.44-1.06V5H3V3.5h3.75v-.75h4.5v.75H15V5h-.75v9.75c0 .412-.147.766-.44 1.06-.294.293-.648.44-1.06.44h-7.5ZM12.75 5h-7.5v9.75h7.5V5Zm-6 8.25h1.5V6.5h-1.5v6.75Zm3 0h1.5V6.5h-1.5v6.75Z" />
        </svg>
    );
}
export { DeleteIcon };
