import type { ComponentProps } from 'react';

function PlusIcon({ ...props }: ComponentProps<'svg'>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            viewBox="0 0 24 24"
            {...props}
        >
            <path d="M5 12h14M12 5v14" />
        </svg>
    );
}

export { PlusIcon };
