import { selectDrawingBlocked } from 'farm-editing/farm-editing-state';
import { useAppSelector } from 'system/store';

const useIsDrawingBlocked = () => {
    const isDrawingBlocked = useAppSelector((state) => selectDrawingBlocked(state));

    return {
        isDrawingBlocked,
    };
};

export { useIsDrawingBlocked };
