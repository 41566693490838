import { stopEditing } from 'farm-editing/farm-editing-state';
import { startNewField } from 'farm-editing/start-editing-reducer';
import { useAppDispatch } from 'system/store';

const useDrawFieldActions = () => {
    const dispatch = useAppDispatch();

    return {
        startFieldEditingHandler: () => dispatch(startNewField()),
        stopFieldEditingdHandler: () => dispatch(stopEditing()),
    };
};

export { useDrawFieldActions };
