import { calcArea } from '@fieldmargin/webapp-geo';
import { formatAreaNicely } from 'lib/geo/maths';
import { useAppSelector } from 'system/store';
import { selectUserAreaMeasurementUnit } from 'users/user-state';

import { useEditingGeoFeatureCollection } from './useEditingGeoFeatureCollection';

const useShapeAreaValue = () => {
    const measurementUnit = useAppSelector(selectUserAreaMeasurementUnit);
    const { editingGeoFeatureCollection } = useEditingGeoFeatureCollection();
    const currentFeatureGeometry = editingGeoFeatureCollection
        ?.get('features')
        ?.get(0)
        ?.get('geometry');

    const currentFeatureGeometryArea = currentFeatureGeometry
        ? calcArea(currentFeatureGeometry)
        : 0;

    const areaResult = currentFeatureGeometryArea;

    const shapeFieldArea = formatAreaNicely(areaResult, measurementUnit);

    return {
        shapeFieldArea,
    };
};

export { useShapeAreaValue };
