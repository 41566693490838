import type { FieldUsageWithFields } from 'fields/fields-selectors';
import { selectMaybeVisibleMaybeArchivedFieldUsagesWithFieldsGroupedByArchived } from 'fields/fields-selectors';
import type { List } from 'immutable';
import { useAppSelector } from 'system/store';

interface FieldUsagesWithFieldsSchema {
    active: List<FieldUsageWithFields>;
    archived: List<FieldUsageWithFields>;
}

const useFieldUsagesWithFields = () => {
    const fieldUsagesWithFields = useAppSelector(
        selectMaybeVisibleMaybeArchivedFieldUsagesWithFieldsGroupedByArchived
    );

    return {
        // TypeScript doesn't infer the types for active and archived
        fieldUsagesWithFields: fieldUsagesWithFields as unknown as FieldUsagesWithFieldsSchema,
    };
};

export { useFieldUsagesWithFields };
