import { useNavigate } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { useAgreenaToken } from '@notes-web/agreena/src/hooks/useAgreenaToken';
import { useAgreenaTracking } from '@notes-web/agreena/src/hooks/useAgreenaTracking';
import CreateFarmContainer from 'farms/create/CreateFarmContainer';

function CreateFarmAgreena() {
    const navigate = useNavigate();
    const { agreenaToken } = useAgreenaToken();
    const { trackCreateFarm } = useAgreenaTracking();

    const onSuccess = (farm: Farm) => {
        trackCreateFarm();
        navigate(`/farms/${farm.uuid}/onboarding`);
    };

    return (
        <CreateFarmContainer
            onSuccess={onSuccess}
            initialValues={{
                farmName: agreenaToken?.farmName || '',
                externalId: agreenaToken?.farmId,
            }}
        />
    );
}

export { CreateFarmAgreena };
