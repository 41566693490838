import type { ComponentProps } from 'react';

function EditIcon({ fill = 'currentColor', ...props }: ComponentProps<'svg'>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 18 18" {...props}>
            <path
                d="M7.25 21H3v-4.25L16.2 3.575c.2-.183.421-.325.663-.425.241-.1.495-.15.762-.15s.525.05.775.15c.25.1.467.25.65.45L20.425 5c.2.183.346.4.438.65a2.14 2.14 0 0 1 0 1.512 1.879 1.879 0 0 1-.438.663L7.25 21Zm7.525-13.2L5 17.575V19h1.425L16.2 9.225 14.775 7.8Z"
                fill={fill}
                transform="translate(-3 -3)"
            />
        </svg>
    );
}
export { EditIcon };
