import { AddFieldsBase } from '@notes-web/components';
import { addFieldOptions } from '@notes-web/utils';

import { VITE_APP_NAME } from '../../../../../env';

function AddFields() {
    return (
        <AddFieldsBase
            options={addFieldOptions(VITE_APP_NAME, ['detect-boundaries', 'rpa', 'john-deere'])}
        />
    );
}

export { AddFields };
