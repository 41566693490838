import { Sidebar } from '@notes-web/components';
import { CircleDrawIcon, FieldDrawIcon } from '@notes-web/icons';
import { ButtonBase } from 'components/button-base';
import { useDrawSelectTool } from 'hooks/useDrawSelectTool';
import { useIsDrawingBlocked } from 'hooks/useIsDrawingBlocked';

import { useAppTranslate } from '../../hooks/useAppTranslate';

import styles from './DrawBoundaryOptions.module.css';

interface DrawBoundaryOptions {
    onDrawCircle?: () => void;
    onDrawArea?: () => void;
}

function DrawBoundaryOptions({ onDrawCircle, onDrawArea }: DrawBoundaryOptions) {
    const { t, t_agreena } = useAppTranslate();
    const { selectTool } = useDrawSelectTool();
    const { isDrawingBlocked } = useIsDrawingBlocked();

    const onDrawCircleHandler = () => {
        selectTool('circle');
        onDrawCircle?.();
    };

    const onDrawAreaHandler = () => {
        selectTool('area');
        onDrawArea?.();
    };

    return (
        <Sidebar.Card>
            <Sidebar.Title>{t_agreena('detect_boundaries_draw_shape_title')}</Sidebar.Title>
            <Sidebar.Description>{t('draw_select_shape')}</Sidebar.Description>
            <div className={styles.buttonsGroup}>
                <ButtonBase.Root
                    variant="outline-primary"
                    onPress={onDrawAreaHandler}
                    isDisabled={isDrawingBlocked}
                >
                    <ButtonBase.Icon>
                        <FieldDrawIcon />
                    </ButtonBase.Icon>
                    <span>{t('area')}</span>
                </ButtonBase.Root>

                <ButtonBase.Root
                    variant={'outline-primary'}
                    onPress={onDrawCircleHandler}
                    isDisabled={isDrawingBlocked}
                >
                    <ButtonBase.Icon>
                        <CircleDrawIcon />
                    </ButtonBase.Icon>
                    <span>{t('circle')}</span>
                </ButtonBase.Root>
            </div>
        </Sidebar.Card>
    );
}

export { DrawBoundaryOptions };
