import type { GeoObject } from '@fieldmargin/webapp-geo';
import { calcArea, calcLength } from '@fieldmargin/webapp-geo';
import { countPointsInGeometry } from 'lib/geo/maths';

export interface Sizes {
    points: number;
    length: number;
    area: number;
}

interface CacheItem {
    geometry: GeoObject;
    sizes: Sizes;
}

const cache: Record<string, CacheItem> = {};

/**
 * Calculates the sizes of a geometry and caches the result.
 */
export const getSizesOfGeometry = (uuid: string, geometry: GeoObject) => {
    const cacheItem = cache[uuid];
    if (cacheItem && cacheItem.geometry === geometry) {
        return cacheItem.sizes;
    }
    const sizes = {
        points: countPointsInGeometry(geometry),
        length: calcLength(geometry),
        area: calcArea(geometry),
    };
    cache[uuid] = {
        geometry,
        sizes,
    };
    return sizes;
};
