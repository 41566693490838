import { Outlet, type RouteObject } from 'react-router-dom';
import { APP_ROUTES } from '@notes-web/types';

import { DownloadCompletion } from '../components/DownloadCompletion';
import { Fields } from '../components/Fields';
import { SaveDrawBoundaries } from '../components/SaveDrawBoundaries';
import { ProtectedCompletion } from '../pages/ProtectedCompletion';

export const fieldsRoutes: RouteObject = {
    path: APP_ROUTES.Fields.Root,
    element: <Outlet />,

    children: [
        { index: true, element: <Fields /> },

        {
            element: <ProtectedCompletion />,

            children: [
                { path: APP_ROUTES.Fields.DownloadCompletion, element: <DownloadCompletion /> },
            ],
        },

        {
            path: APP_ROUTES.Fields.Field,
            element: <Outlet />,
            children: [
                {
                    index: true,
                    element: <SaveDrawBoundaries />,
                },

                {
                    path: APP_ROUTES.Fields.Edit,
                    element: <SaveDrawBoundaries />,
                },
            ],
        },
    ],
};
