import { createRoot } from 'react-dom/client';
import { AgreenaApp } from '@notes-web/agreena';
import { initializeFirebase } from 'lib/firebase/firebase-connection';

// Initialize Firebase
initializeFirebase();

createRoot(document.getElementById('root')!).render(
    // <StrictMode>
    <AgreenaApp />
    // </StrictMode>
);
