import { useLocalStorage, useReadLocalStorage } from '@notes-web/hooks';
import type { AppState } from 'system/store';

type AgreenaUser = AppState['agreena']['userInfo'];

const useAgreenaUserLS = () => {
    const [_value, setValue, removeValue] = useLocalStorage<AgreenaUser | null>(
        'agreenaUser',
        null
    );
    const agreenaUser = useReadLocalStorage<AgreenaUser>('agreenaUser');

    return { userLS: agreenaUser, setUserLS: setValue, removeUserLS: removeValue };
};

export { useAgreenaUserLS };
