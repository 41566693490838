import { useQueryState } from 'nuqs';

const useEditFieldNameParams = () => {
    const [editFieldName, setEditFieldName] = useQueryState('name');

    return {
        editFieldName,
        setEditFieldName,
    };
};

export { useEditFieldNameParams };
