import { Outlet, type RouteObject } from 'react-router-dom';
import { APP_ROUTES } from '@notes-web/types';

import { DetectAutoBoundaries } from '../components/DetectAutoBoundaries';
import { DetectBoundaries } from '../components/DetectBoundaries';
import { DrawBoundaries } from '../components/DrawBoundaries';
import { EditAutoBoundaries } from '../components/EditAutoBoundaries';
import { SaveAutoBoundaryFields } from '../components/SaveAutoBoundaryFields';
import { SaveDrawBoundaries } from '../components/SaveDrawBoundaries';
import { SelectAutoBoundaries } from '../components/SelectAutoBoundaries';

export const detectBoundariesRoutes: RouteObject[] = [
    {
        path: APP_ROUTES.Boundaries.Root,
        element: <Outlet />,

        children: [
            {
                index: true,
                element: <DetectBoundaries />,
            },

            {
                path: APP_ROUTES.Boundaries.Auto,
                element: <Outlet />,
                children: [
                    {
                        index: true,
                        element: <DetectAutoBoundaries />,
                    },
                    {
                        path: APP_ROUTES.Boundaries.Select,
                        element: <Outlet />,
                        children: [
                            {
                                index: true,
                                element: <SelectAutoBoundaries />,
                            },
                            {
                                path: APP_ROUTES.Boundaries.Edit,
                                element: <EditAutoBoundaries />,
                            },
                            {
                                path: APP_ROUTES.Boundaries.Save,
                                element: <SaveAutoBoundaryFields />,
                            },
                        ],
                    },
                ],
            },
            {
                path: APP_ROUTES.Boundaries.Draw,
                element: <Outlet />,
                children: [
                    {
                        index: true,
                        element: <DrawBoundaries />,
                    },
                    {
                        path: APP_ROUTES.Boundaries.Save,
                        element: <SaveDrawBoundaries />,
                    },
                ],
            },
        ],
    },
];
