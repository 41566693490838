import { useNavigate } from 'react-router-dom';
import { Sidebar } from '@notes-web/components';
import { useEditingFieldUuids } from 'hooks/useEditingFieldUuids';
import { useRemoveFieldEditing } from 'hooks/useRemoveFieldEditing';

import { useAppTranslate } from '../../hooks/useAppTranslate';

function EditAutoBoundaries() {
    const { removeFieldEditing } = useRemoveFieldEditing();
    const navigate = useNavigate();
    const { t_agreena } = useAppTranslate();
    const { editingFieldUuids } = useEditingFieldUuids();

    const onPressButton = () => {
        removeFieldEditing();
        navigate('../save');
    };

    const onNavigateBack = () => {
        removeFieldEditing();
    };

    return (
        <Sidebar.Root>
            <Sidebar.Body>
                <Sidebar.Navigation onBack={onNavigateBack} />

                <Sidebar.Header>
                    <Sidebar.Heading>{t_agreena('edit_heading_boundaries_title')}</Sidebar.Heading>
                    <Sidebar.Description>
                        {t_agreena('detect_auto_boundaries_edit_description')}
                    </Sidebar.Description>
                </Sidebar.Header>
            </Sidebar.Body>

            <Sidebar.Footer>
                <Sidebar.ButtonFooter
                    onPress={onPressButton}
                    isDisabled={editingFieldUuids.size === 0}
                >
                    {t_agreena('detect_auto_boundaries_selected_fields_continue_to_name')}
                </Sidebar.ButtonFooter>
            </Sidebar.Footer>
        </Sidebar.Root>
    );
}

export { EditAutoBoundaries };
