import PageLoading from 'components/PageLoading';

import { useAgreenaAuth } from '../hooks/useAgreenaAuth';

const AgreenAuth = () => {
    useAgreenaAuth();

    return <PageLoading />;
};

export default AgreenAuth;
