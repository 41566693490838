import { useNavigate } from 'react-router-dom';
import { Sidebar } from '@notes-web/components';
import { clsx } from 'clsx';
import { useEditingFieldUuids } from 'hooks/useEditingFieldUuids';
import { useEditingGeoFeatureCollection } from 'hooks/useEditingGeoFeatureCollection';
import { useEditSelectedFields } from 'hooks/useEditSelectedFields';
import { defaultToZero } from 'lib/fp-helpers';

import { useAppTranslate } from '../../hooks/useAppTranslate';

import styles from './SelectAutoBoundaries.module.css';

function SelectAutoBoundaries() {
    const { t, t_agreena } = useAppTranslate();
    const navigate = useNavigate();
    const { editingFieldUuids } = useEditingFieldUuids();
    const { editingGeoFeatureCollection } = useEditingGeoFeatureCollection();
    const { handlerEditSelectedFields } = useEditSelectedFields();

    const selectedFieldsStyleVariant = clsx(
        styles.selectedFields,
        editingFieldUuids.size === 0 ? styles.selectedFieldsInactive : styles.selectedFieldsActive
    );

    const onPressButton = () => {
        handlerEditSelectedFields();
        navigate('edit');
    };

    return (
        <Sidebar.Root>
            <Sidebar.Body>
                <Sidebar.Navigation />

                <Sidebar.Header>
                    <Sidebar.Heading>
                        {t('addFields.options.detectBoundaries.title')}
                    </Sidebar.Heading>
                    <Sidebar.Description>
                        {t_agreena('detect_auto_boundaries_select_description')}
                    </Sidebar.Description>
                </Sidebar.Header>

                <Sidebar.Card>
                    <Sidebar.Title>
                        {t_agreena('detect_auto_boundaries_selected_fields_title')}
                    </Sidebar.Title>

                    <Sidebar.Description>
                        {t_agreena('detect_auto_boundaries_selected_fields_description', {
                            count: editingFieldUuids.size,
                            sprintf: [defaultToZero(editingGeoFeatureCollection?.features.size)],
                        })}
                    </Sidebar.Description>

                    <div className={selectedFieldsStyleVariant}>
                        {t_agreena('detect_auto_boundaries_selected_fields_total_selected', {
                            count: editingFieldUuids.size,
                            sprintf: [editingFieldUuids.size],
                        })}
                    </div>
                </Sidebar.Card>
            </Sidebar.Body>

            <Sidebar.Footer>
                <Sidebar.ButtonFooter
                    onPress={onPressButton}
                    isDisabled={editingFieldUuids.size === 0}
                >
                    {t('field_boundary_auto_select_boundaries_continue')}
                </Sidebar.ButtonFooter>
            </Sidebar.Footer>
        </Sidebar.Root>
    );
}

export { SelectAutoBoundaries };
