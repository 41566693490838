import { useTranslation } from 'react-i18next';
import { ButtonBase } from 'components/button-base';
import type Field from 'fields/Field';
import { useDeleteField } from 'fields/sidebar/field-hooks';

import styles from './DeleteFieldPrompt.module.css';
interface DeleteFieldPromptProps {
    field: Field;
    onCancel: () => void;
}

function DeleteFieldPrompt({ field, onCancel }: DeleteFieldPromptProps) {
    const { t } = useTranslation();
    const { onDeleteField } = useDeleteField();

    const onDelete = () => {
        onDeleteField({ field, onDelete: onCancel });
    };

    return (
        <div className={styles.deletePromptWrapper}>
            <strong>{t('message_delete_confirmation_field')}</strong>

            <div className={styles.buttonsGroup}>
                <ButtonBase.Root variant="outline-primary" onPress={onCancel}>
                    Cancel
                </ButtonBase.Root>

                <ButtonBase.Root variant="danger" onPress={onDelete}>
                    Yes
                </ButtonBase.Root>
            </div>
        </div>
    );
}

export { DeleteFieldPrompt };
