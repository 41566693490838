import type { ComponentProps } from 'react';

function FieldDrawIcon({ fill = 'currentColor', ...props }: ComponentProps<'svg'>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 58 55" {...props}>
            <path
                d="M11.512 40.837A6.08 6.08 0 0 1 7.1 42.7C3.7 42.7 1 40 1 36.6a6.058 6.058 0 0 1 5.831-6.094l1.422-6.855A6.065 6.065 0 0 1 5.6 18.6c0-3.4 2.7-6.1 6.1-6.1 1.769 0 3.349.731 4.457 1.911L39.8 8.869A6.06 6.06 0 0 1 45.9 2.8c3.3 0 6.1 2.7 6.1 6.1a6.08 6.08 0 0 1-1.821 4.371l3.009 11.136A6.058 6.058 0 0 1 59 30.5c0 3.4-2.7 6.1-6.1 6.1a6.206 6.206 0 0 1-1.743-.247l-7.374 11.83c.492.873.785 1.87.817 2.917 0 3.4-2.7 6.1-6.1 6.1-3.4 0-6.1-2.7-6.1-6.1v-.029L11.512 40.837Zm-.806-9.145a6.085 6.085 0 0 1 2.472 5.432l20.575 10.12A6.07 6.07 0 0 1 38.5 45c.775 0 1.518.155 2.201.434l7.154-11.478A6.109 6.109 0 0 1 46.8 30.5a6.07 6.07 0 0 1 2.439-4.899l-2.882-10.617c-.151.01-.303.016-.457.016a6.07 6.07 0 0 1-4.784-2.29l-23.332 5.444c.011.147.016.296.016.446a6.055 6.055 0 0 1-5.6 6.08l-1.494 7.012ZM7.1 39.7c1.7 0 3.1-1.4 3.1-3.1 0-1.7-1.4-3.1-3.1-3.1-1.7 0-3.1 1.4-3.1 3.1 0 1.7 1.4 3.1 3.1 3.1Zm4.6-18c1.7 0 3.1-1.4 3.1-3.1 0-1.7-1.4-3.1-3.1-3.1-1.7 0-3.1 1.4-3.1 3.1 0 1.7 1.4 3.1 3.1 3.1Zm26.8 32.5c1.7 0 3-1.4 3.1-3.1 0-1.7-1.4-3.1-3.1-3.1-1.7 0-3.1 1.4-3.1 3.1 0 1.7 1.4 3.1 3.1 3.1Zm14.4-20.6c1.7 0 3.1-1.4 3.1-3.1 0-1.7-1.4-3.1-3.1-3.1-1.7 0-3.1 1.4-3.1 3.1 0 1.7 1.4 3.1 3.1 3.1Zm-7-21.6c1.7 0 3.1-1.4 3.1-3.1 0-1.7-1.4-3.1-3.1-3.1-1.7 0-3.1 1.4-3.1 3.1 0 1.7 1.4 3.1 3.1 3.1Z"
                fill={fill}
            />
        </svg>
    );
}

export { FieldDrawIcon };
