import { createBrowserRouter, Navigate, type RouteObject, RouterProvider } from 'react-router-dom';
import { AgreenaFarmLoader } from '@notes-web/agreena';
import { App } from '@notes-web/app';
import { johnDeereRoutes } from '@notes-web/components';
import { APP_ROUTES } from '@notes-web/types';
import AuthorizationCompletion from 'auth/AurthorizationCompletion';
import UserDeleteSuccess from 'auth/general/UserDeleteSuccess';
import { NuqsAdapter } from 'nuqs/adapters/react-router';
import FarmOverview from 'view/FarmOverview';

import AgreenaAuth from '../app/AgreenaAuth';
import { AgreenaAuthenticatedContainer } from '../components/AgreenaAuthenticatedContainer';
import { TrackPage } from '../tracking/TrackPage';

import { addFieldsRoutes } from './add-fields.routes';
import { createFarmRoutes } from './createFarm';
import { detectBoundariesRoutes } from './detect-boundaries';
import { fieldsRoutes } from './fields.routes';
import { onboardingRoutes } from './onboarding.routes';
import { rpaRoutes } from './rpa.routes';

export const agreenaRoutes: RouteObject[] = [
    {
        path: APP_ROUTES.Root,
        element: <App />,

        children: [
            {
                path: '',
                element: <TrackPage />,
                children: [
                    {
                        path: 'agreena-auth',
                        element: <AgreenaAuth />,
                    },
                    {
                        path: 'auth/complete',
                        element: <AuthorizationCompletion />,
                    },
                    {
                        path: 'account/delete/success',
                        element: <UserDeleteSuccess />,
                    },

                    {
                        path: APP_ROUTES.Root,
                        element: <AgreenaAuthenticatedContainer />,

                        children: [
                            createFarmRoutes,

                            {
                                path: APP_ROUTES.Farms.Farm,
                                element: <AgreenaFarmLoader />,

                                children: [
                                    johnDeereRoutes,
                                    {
                                        element: <FarmOverview />,

                                        children: [
                                            onboardingRoutes,
                                            addFieldsRoutes,
                                            fieldsRoutes,
                                            rpaRoutes,
                                            ...detectBoundariesRoutes,
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },

            // Catch-all route to handle unmatched routes within AuthenticatedContainer
            {
                path: '*',
                element: <Navigate to={APP_ROUTES.Root} replace />,
            },
        ],
    },
];

const router = createBrowserRouter(agreenaRoutes);

export function AppRouterAgreena() {
    return (
        <NuqsAdapter>
            <RouterProvider router={router} />
        </NuqsAdapter>
    );
}
