import { useNavigate } from 'react-router-dom';
import { RPAImportContainerBase } from 'rpa/RPAImportContainerBase';

function RPAImportContainer() {
    const navigate = useNavigate();

    const onNavigateback = () => {
        navigate(-1);
    };

    const onFinish = () => {
        navigate('../fields');
    };
    return <RPAImportContainerBase onBack={onNavigateback} onFinish={onFinish} />;
}

export { RPAImportContainer };
