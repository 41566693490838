import { useNavigate } from 'react-router-dom';

const useNavigateBack = () => {
    const navigate = useNavigate();
    const onNavigateBackHandler = () => {
        navigate(-1);
    };
    return {
        onNavigateBackHandler,
    };
};

export { useNavigateBack };
