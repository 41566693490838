import type { FindByUserWithOwnersSchema } from '@notes-web/api-schema';
import { apiSlice } from 'api/base.api';

export const findByUserWithOwnersApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        findByUserWithOwners: builder.query<FindByUserWithOwnersSchema[], void>({
            query: () => ({
                url: '/farms-api/v2/farms/findByUserWithOwners/',
            }),
        }),
    }),
});

export const { useFindByUserWithOwnersQuery } = findByUserWithOwnersApi;
