import { useTranslation } from 'react-i18next';
import type { TOptions } from 'i18next';

const useAppTranslate = () => {
    const { t } = useTranslation();
    const t_agreena = (key: string, options?: TOptions) => {
        return t(key, { ...options, ns: 'agreena' });
    };

    return {
        t,
        t_agreena,
    };
};

export { useAppTranslate };
