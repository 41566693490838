import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { makeAuthorizationRequest } from '@fieldmargin/webapp-auth';
import { findByUserWithOwners } from '@fieldmargin/webapp-farms';
import { usePromise } from '@fieldmargin/webapp-state';
import { FarmInitialise, useAppInitialization } from '@notes-web/app';
import AppError from 'components/AppError';
import PageLoading from 'components/PageLoading';
import { setFarms } from 'farms/farms-state';
import { useAction } from 'lib/hooks';
import { getUserAccount, setUser } from 'users/user-state';

/**
 * Any routes within this container will require the user to have logged in.
 * If the user is not logged in they'll be redirect to the sign in page.
 */

const AgreenaAuthenticatedContainer = () => {
    const { signedIn } = useAppInitialization();

    const setUserAction = useAction(setUser);
    const setFarmsAction = useAction(setFarms);
    const { notYetFetched, pending, error, setPromise } = usePromise();

    // console.log(pathname)
    useEffect(() => {
        setPromise(
            Promise.all([
                getUserAccount().then(setUserAction),
                findByUserWithOwners().then(setFarmsAction),
            ])
        );
    }, []);

    if (!signedIn || notYetFetched || pending) {
        if (!signedIn) {
            makeAuthorizationRequest();
        }
        return <PageLoading />;
    }

    if (error) {
        return <AppError />;
    }

    return (
        <FarmInitialise nextRoute="fields">
            <Outlet />
        </FarmInitialise>
    );
};

export { AgreenaAuthenticatedContainer };
