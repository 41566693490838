import { setEditingData } from 'farm-editing/farm-editing-state';
import { useAppDispatch } from 'system/store';

const useRemoveFieldEditing = () => {
    const dispatch = useAppDispatch();

    const removeFieldEditing = () => {
        dispatch(setEditingData({}));
    };

    return {
        removeFieldEditing,
    };
};

export { useRemoveFieldEditing };
