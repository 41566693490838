import { Navigate, Outlet, useLocation } from 'react-router-dom';

// The completion page cannot be accessed directly
// The state.redirect is set to false when the user click the download fields for agreena

function ProtectedCompletion() {
    const { state } = useLocation();
    const isCompletion = state && 'completion' in state;

    return isCompletion ? <Outlet /> : <Navigate to="../fields" replace />;
}

export { ProtectedCompletion };
