import { useEditingGeoFeatureCollection } from 'hooks/useEditingGeoFeatureCollection';

const useHasEditingFeaturesOrField = () => {
    const { editingGeoFeatureCollection } = useEditingGeoFeatureCollection();

    const hasEditingFeaturesOrField =
        editingGeoFeatureCollection && editingGeoFeatureCollection?.get('features')?.size !== 0;

    return { hasEditingFeaturesOrField };
};

export { useHasEditingFeaturesOrField };
