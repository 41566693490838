import { useCallback, useEffect, useState } from 'react';

import { useEventListener } from './useEventListener';

// https://usehooks-ts.com/react-hook/use-read-local-storage

const IS_SERVER = typeof window === 'undefined';

type Options<T, InitializeWithValue extends boolean | undefined> = {
    deserializer?: (value: string) => T;
    initializeWithValue: InitializeWithValue;
};

// SSR version
export function useReadLocalStorage<T>(
    key: string,
    options: Options<T, false>
): T | null | undefined;
// CSR version
export function useReadLocalStorage<T>(key: string, options?: Partial<Options<T, true>>): T | null;
export function useReadLocalStorage<T>(
    key: string,
    options: Partial<Options<T, boolean>> = {}
): T | null | undefined {
    let { initializeWithValue = true } = options;
    if (IS_SERVER) {
        initializeWithValue = false;
    }

    const deserializer = useCallback<(value: string) => T | null>(
        (value) => {
            if (options.deserializer) {
                return options.deserializer(value);
            }
            // Support 'undefined' as a value
            if (value === 'undefined') {
                return undefined as unknown as T;
            }

            let parsed: unknown;
            try {
                parsed = JSON.parse(value);
            } catch (error) {
                console.error('Error parsing JSON:', error);
                return null;
            }

            return parsed as T;
        },
        [options]
    );

    // Get from local storage then
    // parse stored json or return initialValue
    const readValue = useCallback((): T | null => {
        // Prevent build error "window is undefined" but keep keep working
        if (IS_SERVER) {
            return null;
        }

        try {
            const raw = window.localStorage.getItem(key);
            return raw ? deserializer(raw) : null;
        } catch (error) {
            console.warn(`Error reading localStorage key “${key}”:`, error);
            return null;
        }
    }, [key, deserializer]);

    const [storedValue, setStoredValue] = useState(() => {
        if (initializeWithValue) {
            return readValue();
        }
        return undefined;
    });

    // Listen if localStorage changes
    useEffect(() => {
        setStoredValue(readValue());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key]);

    const handleStorageChange = useCallback(
        (event: StorageEvent | CustomEvent) => {
            if ((event as StorageEvent).key && (event as StorageEvent).key !== key) {
                return;
            }
            setStoredValue(readValue());
        },
        [key, readValue]
    );

    // this only works for other documents, not the current one
    useEventListener('storage', handleStorageChange);

    // this is a custom event, triggered in writeValueToLocalStorage
    // See: useLocalStorage()
    useEventListener('local-storage', handleStorageChange);

    return storedValue;
}
