import { Fragment, useState } from 'react';
import { extentFromGeo } from '@fieldmargin/webapp-geo';
import { setMapView } from 'farm-editing/farm-editing-state';
import type Field from 'fields/Field';
import type { List } from 'immutable';
import { useAppDispatch } from 'system/store';

import { DeleteFieldPrompt } from './DeleteFieldPrompt';
import { FieldItem } from './FieldItem';
import { FieldRow } from './FieldRow';

interface FieldsListProps {
    fields: List<Field>;
}

function FieldsList({ fields }: FieldsListProps) {
    const [selectedFieldIndex, setSelectedFieldIndex] = useState<number | null>(null);
    const dispatch = useAppDispatch();
    const onCancel = () => {
        setSelectedFieldIndex(null);
        dispatch(
            setMapView({
                type: 'full-extent',
            })
        );
    };

    const onDelete = (field: Field, index: number) => {
        const geo = field?.get('geoJson')?.get('features')?.get(0);

        setSelectedFieldIndex(index);

        if (geo) {
            dispatch(
                setMapView({
                    type: 'extent',
                    payload: extentFromGeo(geo),
                })
            );
        }
    };

    return (
        <Fragment>
            {fields.map((field, index) => {
                return (
                    <FieldRow key={field.uuid}>
                        {selectedFieldIndex === index ? (
                            <DeleteFieldPrompt field={field} onCancel={onCancel} />
                        ) : (
                            <FieldItem field={field} onDelete={() => onDelete(field, index)} />
                        )}
                    </FieldRow>
                );
            })}
        </Fragment>
    );
}

export { FieldsList };
