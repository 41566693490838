import type { ComponentProps } from 'react';
import { useNavigate } from 'react-router-dom';
import { OnboardingBase } from '@notes-web/components';
import { usePageParams } from 'hooks/usePageParamas';

import { useAppTranslate } from '../../hooks/useAppTranslate';

function Onboarding() {
    const { t_agreena } = useAppTranslate();
    const navigate = useNavigate();
    const { farmUuid } = usePageParams();

    const onContinue = () => navigate(`/farms/${farmUuid}/add-fields`);

    const timeline = [
        {
            heading: t_agreena('onboarding_step_1_heading'),
            list: [
                t_agreena('onboarding_step_1_list_1'),
                t_agreena('onboarding_step_1_list_2'),
                t_agreena('onboarding_step_1_list_3'),
                t_agreena('onboarding_step_1_list_4'),
            ],
        },
        {
            heading: t_agreena('onboarding_step_2_heading'),
            description: t_agreena('onboarding_step_2_description'),
        },
        { heading: t_agreena('onboarding_step_3_heading') },
        {
            heading: t_agreena('onboarding_step_4_heading'),
            description: t_agreena('onboarding_step_4_description'),
        },
    ] satisfies ComponentProps<typeof OnboardingBase>['timeline'];

    return (
        <OnboardingBase
            title={t_agreena('fields_page_heading')}
            description={t_agreena('onboarding_page_description')}
            timeline={timeline}
            onContinue={onContinue}
        />
    );
}

export { Onboarding };
