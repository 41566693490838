import type { ApplicationLayoutProps } from '@notes-web/layout';
import { AplicationLayoutProvider } from '@notes-web/layout';

import { Header } from '../components/Header';
import { AppRouterAgreena } from '../router/agreenaRouter';

const createFarm: ApplicationLayoutProps['CreateFarm'] = {};

function AgreenaApp() {
    return (
        <AplicationLayoutProvider CreateFarm={createFarm} Header={Header}>
            <AppRouterAgreena />
        </AplicationLayoutProvider>
    );
}

export { AgreenaApp };
