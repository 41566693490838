import { useAppSelector } from 'system/store';

import { useAgreenaUserLS } from './useAgreeaUserLS';

const useAgreenaToken = () => {
    const agreenaToken = useAppSelector((state) => state.agreena.userInfo);
    const { userLS } = useAgreenaUserLS();
    const tokenData = agreenaToken ?? userLS;

    return { agreenaToken: tokenData };
};

export { useAgreenaToken };
