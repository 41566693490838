import { useCheckFieldName } from './useCheckFieldName';

type NamesFrom = { [k: string]: string };

const checkDuplicates = (
    formValues: Record<string | number, string | undefined>
): Record<string | number, boolean> | undefined => {
    if (!formValues) return;

    const valueCount: Record<string, number> = {};
    const result: Record<string | number, boolean> = {};

    Object.entries(formValues).forEach(([key, value]) => {
        if (value !== '' && value !== undefined) {
            valueCount[value] = (valueCount[value] || 0) + 1;
            result[key] = valueCount[value] > 1;
        } else {
            result[key] = false;
        }
    });

    return result;
};

export const hasNoDuplicates = (obj: Record<string, boolean>): boolean => {
    return Object.values(obj).every((value) => value === false);
};

const useCheckFieldNamesDuplicates = () => {
    const { isNameExists } = useCheckFieldName();

    const nameValues = (names: NamesFrom) => checkDuplicates(names);

    const hasDuplicates = (names: NamesFrom) => {
        const _nameValues = nameValues(names);

        const hasInternalDuplicates = _nameValues && !hasNoDuplicates(_nameValues);
        const hasExternalDuplicates = Object.values(names).some(
            (name) => name && isNameExists(name)
        );

        return hasInternalDuplicates || hasExternalDuplicates;
    };

    const isDuplicate = ({
        name,
        names,
        featureId,
    }: {
        name?: string;
        names?: NamesFrom;
        featureId?: string | number;
    }) => {
        if (name) {
            return isNameExists(name);
        }
        if (names && featureId) {
            return nameValues(names)?.[featureId] || isNameExists(names?.[featureId]);
        }
    };
    return { isDuplicate, hasDuplicates };
};

export { useCheckFieldNamesDuplicates };
