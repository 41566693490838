import { List, Record } from 'immutable';

const FarmIntegration = Record({
    uuid: '',
    farmUuid: '',
    integrationUuid: '',

    addedByUserId: -1,
    agreedScopes: List<string>(),
    active: false,

    name: '',
    logoUri: '',
    public: false,
});
interface FarmIntegration extends ReturnType<typeof FarmIntegration> {}

export interface FarmIntegrationDTO {
    uuid: '';
    farmUUID: string;
    integrationUUID: string;
    addedByUserId: number;
    agreedScopes: string[];
    active: boolean;
    name: string;
    logoUri: string;
    public: boolean;
}

export const deserializeFarmIntegration = (json: FarmIntegrationDTO) =>
    FarmIntegration({
        ...json,
        farmUuid: json.farmUUID,
        integrationUuid: json.integrationUUID,
        agreedScopes: List(json.agreedScopes),
    });

export default FarmIntegration;
