import type { PropsWithChildren } from 'react';
import { createContext, useContext } from 'react';
import { Dialog, DialogTrigger, Modal, ModalOverlay } from 'react-aria-components';
import { useTranslation } from 'react-i18next';
import type { Blocker } from 'react-router-dom';
import { useBlocker } from 'react-router-dom';
import { ButtonBase } from 'components/button-base';
import { t } from 'i18next';

import styles from './PromptBlocker.module.css';

interface BlockerContextState {
    blocker: Blocker;
}

interface PromptBlockerRootProps extends PropsWithChildren {
    isBlocked: boolean;
}

const PromptBlockerContext = createContext({} as BlockerContextState);

const usePromptBlocker = () => {
    return useContext(PromptBlockerContext);
};

export function PromptBlockerRoot({ isBlocked, children }: PromptBlockerRootProps) {
    const { t } = useTranslation();

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
            isBlocked && currentLocation.pathname !== nextLocation.pathname
    );
    return blocker.state === 'blocked' ? (
        <PromptBlockerContext.Provider value={{ blocker }}>
            <DialogTrigger isOpen={blocker.state === 'blocked'}>
                <ModalOverlay className={styles.modalOverlay}>
                    <Modal>
                        <Dialog className={styles.dialog}>
                            <div className={styles.dialogHeading}>
                                {t('unsaved_changes_header')}
                            </div>
                            <div>
                                <div>{t('field_bulk_edit_unsaved_changes1')}</div>
                                <div>{t('field_bulk_edit_unsaved_changes2')}</div>
                            </div>
                            {children}
                        </Dialog>
                    </Modal>
                </ModalOverlay>
            </DialogTrigger>
        </PromptBlockerContext.Provider>
    ) : null;
}

export function PromptBlockerButtonLeave() {
    const { blocker } = usePromptBlocker();

    const onClickLeave = () => {
        blocker?.proceed?.();
    };

    return (
        <ButtonBase.Root variant="outline-danger" onPress={onClickLeave}>
            {t('label_leave')}
        </ButtonBase.Root>
    );
}

export function PromptBlockerButtonStay() {
    const { blocker } = usePromptBlocker();

    const onClick = () => {
        blocker?.reset?.();
    };

    return (
        <ButtonBase.Root variant="primary" onPress={onClick}>
            {t('cancel')}
        </ButtonBase.Root>
    );
}

export function PromptButtonsGroup({ children }: PropsWithChildren) {
    return <div className={styles.promptButtonsGroup}>{children}</div>;
}
