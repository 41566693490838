import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCurrentField } from 'hooks/selectors';
import { useDrawFieldActions } from 'hooks/useDrawFieldActions';
import { useStartEditingField } from 'hooks/useStartEditingField';

import { useEditFieldNameParams } from './useEditFieldNameParams';

/**
 * - This hook it's a side effect to handle field click on the map
 * - when the field is clicked we navigate user to the edit page
 * - with the field name added as search param
 */

const useEditFieldMap = () => {
    const navigate = useNavigate();
    const { startEditingField } = useStartEditingField();
    const { stopFieldEditingdHandler } = useDrawFieldActions();

    const { currentField } = useCurrentField();
    const { editFieldName, setEditFieldName } = useEditFieldNameParams();

    useEffect(() => {
        if (currentField && !editFieldName) {
            setEditFieldName(currentField.name);
            startEditingField(currentField);
            navigate('edit', { replace: true });
        }

        return () => {
            setEditFieldName(null);
            stopFieldEditingdHandler();
        };
    }, [currentField]);
};

export { useEditFieldMap };
