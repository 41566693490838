import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Sidebar } from '@notes-web/components';
import { ButtonBase } from 'components/button-base';
import { startAutoBoundary } from 'farm-editing/farm-editing-state';
import { useIsDrawingBlocked } from 'hooks/useIsDrawingBlocked';
import { useAppDispatch } from 'system/store';

import { DrawBoundaryOptions } from '../DrawBoundaryOptions';
import { DrawingBlockedCard } from '../DrawingBlockedCard';

function DetectBoundaries() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { isDrawingBlocked } = useIsDrawingBlocked();

    const navigateToDetectBoundaries = () => navigate('auto');

    const onAutoBoundary = () => {
        dispatch(startAutoBoundary());
        navigateToDetectBoundaries();
    };

    const onDrawHandler = () => {
        navigate('draw');
    };

    return (
        <Sidebar.Root>
            <Sidebar.Body>
                <Sidebar.Navigation />

                <Sidebar.Header>
                    <Sidebar.Heading>{t('field_create_boundary')}</Sidebar.Heading>
                </Sidebar.Header>

                <DrawingBlockedCard />

                <Sidebar.Card>
                    <Sidebar.Title>{t('field_boundary_auto_title')}</Sidebar.Title>

                    <Sidebar.Description>{t('field_boundary_auto_desc')}</Sidebar.Description>
                    <ButtonBase.Root
                        variant="outline-primary"
                        onPress={onAutoBoundary}
                        isDisabled={isDrawingBlocked}
                    >
                        {t('field_boundary_auto_detect')}
                    </ButtonBase.Root>
                </Sidebar.Card>

                <DrawBoundaryOptions onDrawArea={onDrawHandler} onDrawCircle={onDrawHandler} />
            </Sidebar.Body>
        </Sidebar.Root>
    );
}

export { DetectBoundaries };
