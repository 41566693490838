import { createAction, handleActions } from '@fieldmargin/webapp-state';
import { loadedFarmSuccess, loadingFarm } from 'farms/farm-loading-state';
import { List, Record } from 'immutable';
import config from 'lib/config';
import { createSelector } from 'reselect';
import type { AppState } from 'system/store';

import type FarmIntegration from './FarmIntegration';

const Integration = Record({
    uuid: '',
    name: '',
    logoUri: '',
    supports: List<string>(),
    listDescription: '',
    fullDescription: '',

    public: false,
    isInternal: false,
    detailsTemplate: null as string | null,
    openText: '',
    openUrl: null as string | null,
    siteUrl: '',
});
export interface Integration extends ReturnType<typeof Integration> {}

export const createIntegrationOpenUrl = (url: string, farmUuid: string) =>
    url.replace('FARM_UUID', farmUuid).replace('SUFFIX', config.get('integrationUrlSuffix') || '');

export const removeFarmIntegration = createAction<IntegrationsState, string>(
    'Remove farm integration',
    (state, payload) =>
        state.update('farmIntegrations', (farmIntegrations) =>
            farmIntegrations !== null
                ? farmIntegrations.filter((fi) => fi.uuid !== payload)
                : farmIntegrations
        )
);

export const IntegrationsState = Record({
    farmIntegrations: null as List<FarmIntegration> | null,
});
export interface IntegrationsState extends ReturnType<typeof IntegrationsState> {}

export const selectHasIntegrationWithScopeConnected = createSelector(
    (state: AppState, _) => state.integrationsState.farmIntegrations,
    (_, scope: string) => scope,
    (farmIntegrations, scope) => {
        if (!farmIntegrations) {
            return null;
        }
        return !!farmIntegrations.find((fi) => fi.agreedScopes.contains(scope));
    }
);

export const selectHasSensorsConnected = (state: AppState) =>
    selectHasIntegrationWithScopeConnected(state, 'sensors:write');
export const selectHasVehiclesConnected = (state: AppState) =>
    selectHasIntegrationWithScopeConnected(state, 'vehicles:write');

export const selectHasPycnoConnected = createSelector(
    (state: AppState) => state.integrationsState.farmIntegrations,
    (farmIntegrations) =>
        farmIntegrations?.find(
            ({ integrationUuid }) => integrationUuid === '204c4a76-2eaa-4e76-82fc-8f01d687ff31'
        ) !== undefined
);

export const integrationsReducer = handleActions(IntegrationsState(), [removeFarmIntegration], {
    [loadingFarm.toString()]: () => IntegrationsState(),

    [loadedFarmSuccess.toString()]: (state, payload) => {
        return state.set('farmIntegrations', payload.farmIntegrations);
    },
});
