import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    initAuth,
    makeAuthorizationRequest,
    sessionIsAuthenticated,
} from '@fieldmargin/webapp-auth';
import { afterAuth } from 'auth/auth';
import { jwtDecode } from 'jwt-decode';
import configService from 'lib/config';
import { useQueryState } from 'nuqs';
import { updateAgreenaToken } from 'store/slices/agreenaToken.slice';
import type { AppState } from 'system/store';
import { useAppDispatch } from 'system/store';

import { useAgreenaUserLS } from './useAgreeaUserLS';
import { useIsExistingFarmId } from './useIsExistingFarm';

type AgreenaUser = AppState['agreena']['userInfo'];

const useAgreenaAuth = () => {
    const dispatch = useAppDispatch();
    const { setUserLS } = useAgreenaUserLS();
    const navigate = useNavigate();
    const [id_token_jwt] = useQueryState('id_token_jwt');
    const { existingFarmId, isLoading } = useIsExistingFarmId();

    useEffect(() => {
        if (!id_token_jwt || isLoading) return;

        const authenticateUser = async () => {
            const userInfo = jwtDecode(id_token_jwt) as AgreenaUser;
            dispatch(updateAgreenaToken(userInfo));
            setUserLS(userInfo);

            // Initialize authentication
            initAuth({
                authApiRoot: configService.get('authApiRoot'),
                apiRoot: configService.get('apiRoot'),
                redirectRoot: window.location.origin,
                afterAuth,
                extras: {
                    prompt: 'none',
                    agreena_id_token: id_token_jwt,
                },
            });

            const isSignedIn = await sessionIsAuthenticated();
            if (!isSignedIn) {
                makeAuthorizationRequest();
                setUserLS(null);
            } else {
                if (existingFarmId) {
                    navigate(`/farms/${existingFarmId}/fields`);
                } else {
                    navigate('/');
                }
            }
        };

        authenticateUser();
    }, [setUserLS, dispatch, id_token_jwt, existingFarmId, isLoading]);
};

export { useAgreenaAuth };
