import type Field from 'fields/Field';
import { useUpdateField } from 'fields/sidebar/field-hooks';
import { useOutOfDatePromise } from 'lib/hooks';

interface UpdateFieldValues {
    name: string;
    id?: string;
}

interface UpdateFieldProps {
    field: Field;
    values: UpdateFieldValues;
    onSave?: () => void;
}

const useUpdateEditField = () => {
    const [pending, outOfDate, error, setPromise] = useOutOfDatePromise();

    const { updateField } = useUpdateField();

    const onUpdateField = ({ field, values, onSave }: UpdateFieldProps) => {
        setPromise(
            updateField(field, { name: values.name, fieldId: values.id ?? '' }).then(onSave)
        );
    };

    return {
        isPending: pending,
        isError: error,
        isOutOfDate: outOfDate,
        onUpdateField,
    };
};

export { useUpdateEditField };
