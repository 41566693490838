import { useFieldUsagesWithFields } from './useFieldUsagesWithFields';

const useCheckFieldName = () => {
    const { fieldUsagesWithFields } = useFieldUsagesWithFields();
    const fields = fieldUsagesWithFields.active.get(0)?.fields;
    const fieldNamesArray = fields?.map((field) => field.name).toJS() || [];

    const fieldNamesSet = new Set(fieldNamesArray);

    function isNameExists(name: string) {
        return fieldNamesSet.has(name);
    }

    return {
        isNameExists,
    };
};

export { useCheckFieldName };
